import styled from "styled-components";
import { Link } from "react-router-dom";

export const ImpressumContainer = styled.div`
  height: max-content;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  background: #696969;
`;

export const ImpressumWrap = styled.div`
  background-color: #fffff;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImpressumTitle = styled.h1`
  color: #fff;
  font-size: 24px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const ImpressumSection = styled.text`
  margin-top: 24px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;
