import React from "react";
import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Impressum from "./components/Impressum/impressum";
import Home from "./pages";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/impressum" element={<Impressum />} exact />
      </Routes>
    </Router>
  );
}

export default App;
