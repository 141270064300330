import styled from "styled-components";

export const HeroContainer = styled.div`
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 500px;
  position: relative;
  /* z-index: 1; */
`;

export const HeroWrapper = styled.div`
  max-width: 1000px;
  justify-content: center;

  align-items: center;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const HeroImg = styled.img`
  width: 850px;
  margin: 50px;
  margin-top: 150px;
  /* float: right; */

  @media screen and (max-width: 1000px) {
    width: 500px;
  }

  @media screen and (max-width: 768px) {
    width: 400px;
  }
`;

export const HeroH1 = styled.h1`
  font-size: 23px;
  align-items: center;
  font-weight: bold;
  color: #0c0c0c;
  margin: 50px;
  text-align: center;
`;
