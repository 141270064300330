import React from "react";
import { HeroContainer, HeroH1, HeroWrapper, HeroImg } from "./HeroElements";

import Svg1 from "../../images/logo.svg";

const Hero = () => {
  return (
    <div>
      <HeroContainer>
        <HeroWrapper>
          <HeroImg
            alt="Jan Maginot Rechtsanwalt Strafverteidiger"
            src={Svg1}
          ></HeroImg>
          <HeroH1>Seite in Überarbeitung</HeroH1>
        </HeroWrapper>
      </HeroContainer>
    </div>
  );
};

export default Hero;
