import React from "react";

import {
  ImpressumContainer,
  ImpressumWrap,
  ImpressumTitle,
  ImpressumSection,
} from "./ImpressumElements";

const Impressum = () => {
  return (
    <>
      <ImpressumContainer>
        <ImpressumWrap>
          <ImpressumTitle> Impressum</ImpressumTitle>
          <ImpressumSection>
            V.i.S.d.P./V.i.S.d. &sect; 5 TMG
            <br />
            Rechtsanwalt Jan Maginot
            <br />
            Herderstra&szlig;e 13 <br />
            65185 Wiesbaden
            <br />
            <br />
            Telefon: 0177 - 6744820
            <br />
            Telefax: 0611 - 94075982
            <br />
            E-Mail: info@maginot-legal.de
            <br />
            Website: www.maginot-legal.de <br />
            <br />
            Inhaltlich Verantwortlicher nach &sect; 18 Abs. 2 MStV: <br />
            Jan Maginot <br />
            Herderstra&szlig;e 13 <br />
            65185 Wiesbaden
            <br />
            <br />
            Umsatzsteuer-Identifikationsnummer: DE347443773
            <br />
            <br />
            <br />
            <b>Berufsrechtliche Angaben:</b>
            <br />
            Die gesetzliche Berufsbezeichnung &#8222;Rechtsanwalt&ldquo; wurde
            verliehen in der Bundesrepublik Deutschland. Die zust&auml;ndige
            Rechtsanwaltskammer f&uuml;r Herrn Rechtsanwalt Maginot ist die
            Rechtsanwaltskammer Frankfurt am Main, Bockenheimer Anlage 36
            &middot; 60322 Frankfurt am Main, Tel.: 069/170098-01, Fax:
            069/170098-50, Mail: info@rak-ffm.de, Web:
            <a
              href="https://www.rak-ffm.de"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.rak-ffm.de
            </a>
            . <br />
            Berufsrechtlichen Regelungen finden sich in der
            Bundesrechtsanwaltsordnung (BRAO), der Berufsordnung f&uuml;r
            Rechtsanw&auml;lte (BORA), dem Rechtsanwaltsverg&uuml;tungsgesetz
            (RVG) sowie der Fachanwaltsordnung (FAO). Der vollst&auml;ndige
            Wortlaut dieser Gesetze, Vorschriften und Verordnungen ist einsehbar
            unter:
            <a
              href="https://www.brak.de/"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.brak.de
            </a>
            .
            <br />
            Rechtsanwalt Maginot ist berufshaftpflichtversichert bei der R+V
            Allgemeine Versicherung AG, Voltastra&szlig;e 84, 60486 Frankfurt.{" "}
            <br />
            <br />
            <b>Haftung f&uuml;r Inhalte:</b>
            <br />
            Es besteht keine Verantwortlichkeit f&uuml;r den Inhalt oder die
            Funktionen externer Seiten, auf die im Rahmen dieser Seiten
            Hyperlinks / Verweise gesetzt sind. In Ansehung aktueller
            Rechtsprechung distanziert sich Rechtsanwalt Maginot
            ausdr&uuml;cklich von referenzierten Seiten.
            <br />
            Um die Richtigkeit der auf diesen Webseiten enthaltenen
            Informationen und Links gew&auml;hrleisten zu k&ouml;nnen, wurden
            gr&ouml;&szlig;te Anstrengungen unternommen. Unabh&auml;ngig davon
            ist jegliche Haftung im Zusammenhang mit der Nutzung der
            Informationen, der Links oder dem Vertrauen auf deren Richtigkeit
            ausgeschlossen. Die Inhalte und Informationen, die Gegenstand dieser
            Webseite sind, stellen ausdr&uuml;cklich keine Rechtsberatung dar.{" "}
            <br /> <br />
            Ausschlie&szlig;lich im Falle von Vorsatz oder grober
            Fahrl&auml;ssigkeit besteht eine Haftung des vorstehend genannten
            Verantwortlichen Ihnen oder Dritten gegen&uuml;ber, soweit es sich
            um direkte, indirekte, spezielle oder sonstige Folgesch&auml;den
            handelt und diese sich gegebenenfalls aus der Nutzung dieser oder
            einer damit verlinkten Website ergeben.
            <br />
            <br />
            <b>
              Urheberrechtliche Angaben: <br />
            </b>
            Einzelne Seiten dieser Webseite d&uuml;rfen zum
            ausschlie&szlig;lichen privaten Gebrauch oder zum Gebrauch innerhalb
            Ihres Unternehmens gedruckt und verwendet werden. Jede andere
            Verbreitung, Vervielf&auml;ltigung oder Wiedergabe dieser Webseite
            bedarf der ausdr&uuml;cklichen Einwilligung von Rechtsanwalt
            Maginot.
            <br />
            Online-Streitbeilegungsplattform und Verbraucherstreitbeilegung
            (Artikel 14 Absatz 1 der ODR-Verordnung und &sect; 36
            Verbraucherschutzgesetz) <br />
            Die Europ&auml;ische Kommission stellt eine Plattform zur
            Online-Streitbeilegung bereit, diese kann unter
            <a
              href="http://ec.europa.eu/consumers/odr/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              ec.europa.eu
            </a>
            .
            <br />
            In Deutschland ist zust&auml;ndige Verbraucherschlichtungsstelle
            f&uuml;r verm&ouml;gensrechtliche Streitigkeiten aus einem
            Mandatsverh&auml;ltnis die Schlichtungsstelle der
            Rechtsanwaltschaft, Neue Gr&uuml;nstra&szlig;e 17, 10179 Berlin, .
            <a
              href="https://www.schlichtungsstelle-der-rechtsanwaltschaft.de/"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.schlichtungsstelle-der-rechtsanwaltschaft.de
            </a>
            .<br />
            Es besteht keine Verpflichtung zur Teilnahme an den vorgenannten
            Schlichtungsverfahren. <br />
            <br />
            <b>Datenschutz:</b>
            <br />
            Alle Rechtsanwalt Maginot &uuml;bersandten Daten werden streng im
            Sinne des Bundesdatenschutzgesetzes (BDSG) sowie der
            Datenschutzgrundverordnung (DS-GVO) behandelt. Das bedeutet
            selbstverst&auml;ndlich auch, dass Daten Dritten gegen&uuml;ber in
            keiner Form zug&auml;nglich gemacht werden. Das Versenden Ihrer
            Daten geschieht allerdings auf einer ungesicherten Verbindung.
            <br />
            <br />
          </ImpressumSection>
        </ImpressumWrap>
      </ImpressumContainer>
    </>
  );
};

export default Impressum;
